import payload_plugin_qLmFnukI99 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_zlvi6dcIsi from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_ucMK8VhJjR from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import locomotive_client_ZezSqBW7MM from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt-locomotive-scroll/dist/runtime/locomotive.client.mjs";
import plugin_gab9g3XuEx from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/nuxt-aos/dist/runtime/plugin.js";
import plugin_WLsn00x1qh from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_RAHspWIKy3 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/.cache/nuxt/.nuxt/unocss.mjs";
import shiki_aNtZOwkiMT from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/shiki.js";
import vueuse_OEgG99GgUe from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/vueuse.js";
import breakpoint_A94uY76UEY from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/breakpoint.js";
import localization_4muIV3rQ3Y from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/localization.js";
import seometa_PUjXh2I6u6 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/seometa.js";
import bootstrap_05n8Uo6bmP from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/usebootstrap/dist/runtime/plugins/bootstrap.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import vue_awesome_paginate_l1b0wTlGPB from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/plugins/vue-awesome-paginate.js";
import recaptcha_W4US4xmBV6 from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/plugins/recaptcha.js";
import aos_Rx1DQ3If9A from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/plugins/aos.js";
import ssr_plugin_B4ptqVdIfe from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
import bootstrap_S5A03TdDOt from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/plugins/bootstrap.js";
import scroll_behavior_dEEhVJa5nx from "/home/forge/shareinvestorthailand.th.cloud.shareinvestor.app/plugins/scroll-behavior.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  plugin_ucMK8VhJjR,
  locomotive_client_ZezSqBW7MM,
  plugin_gab9g3XuEx,
  plugin_WLsn00x1qh,
  unocss_RAHspWIKy3,
  shiki_aNtZOwkiMT,
  vueuse_OEgG99GgUe,
  breakpoint_A94uY76UEY,
  localization_4muIV3rQ3Y,
  seometa_PUjXh2I6u6,
  bootstrap_05n8Uo6bmP,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  vue_awesome_paginate_l1b0wTlGPB,
  recaptcha_W4US4xmBV6,
  aos_Rx1DQ3If9A,
  ssr_plugin_B4ptqVdIfe,
  bootstrap_S5A03TdDOt,
  scroll_behavior_dEEhVJa5nx
]